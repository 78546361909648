import PropTypes from 'prop-types'
import React from 'react'
import { subheadline } from './index.module.scss'

const Subheadline = props => (
  <h2 className={subheadline}>
    <span className="inner-wrapper">{props.headline}</span>
  </h2>
)

Subheadline.propTypes = {
  headline: PropTypes.string.isRequired,
}

Subheadline.defaultProps = {
  headline: '+++ Missing subheadline +++',
}

export default Subheadline
